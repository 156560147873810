import { runInAction, makeAutoObservable } from "mobx";

import { DictionaryValue } from "../model";

import { StatisticsDictionaryApi } from "shared/api";
import { Result } from "shared/api/request";
import { PaginationStore } from "shared/store/Pagination";

const { getOffers, getTimeZone, getAffiliates, getOpponents, getOpponentsAffiliates, getOfferBrandAdvertisers } =
  StatisticsDictionaryApi();

export class StatisticsDictionaryStore {
  offers: DictionaryValue = null;
  timeZone: DictionaryValue = null;
  affiliates: DictionaryValue = null;
  opponents: DictionaryValue = null;
  opponentsAffiliates: DictionaryValue = null;
  brandAdvertisers: DictionaryValue | null = null;

  affiliatesLoading = false;
  affiliatesPagination = new PaginationStore(100);

  constructor() {
    makeAutoObservable(this);
  }

  getAffiliates = () => {
    return this.affiliates;
  };

  loadOffers = async () => {
    if (!this.offers) {
      const result: Result = await getOffers();
      runInAction(() => {
        if (result.isSuccess) {
          this.offers = [...result.result];
        } else {
          this.offers = [];
        }
      });
    }
  };

  loadDefaultAffiliates = async () => {
    if (!this.affiliates) {
      const result = await getAffiliates({
        page: this.affiliatesPagination.page,
        pageSize: this.affiliatesPagination.pageSize,
        name: "",
      });

      runInAction(() => {
        if (result && result.isSuccess) {
          this.affiliates = result.result;
          this.affiliatesPagination.updateTotalCount(result.pagination.totalCount);
        } else {
          this.affiliates = [];
        }
        this.affiliatesLoading = false;
      });
    }
  };

  searchAffiliates = async (name: string) => {
    this.affiliatesLoading = true;

    const result = await getAffiliates({
      page: 1,
      pageSize: 100,
      name: name,
    });

    runInAction(() => {
      if (result?.isSuccess) {
        this.affiliates = [...result.result];
        this.affiliatesPagination.updateTotalCount(result.pagination.totalCount);
      } else {
        this.affiliates = [];
      }
      this.affiliatesPagination.updatePage(1);
      this.affiliatesLoading = false;
    });
  };

  loadAffiliates = async (name = "") => {
    this.affiliatesLoading = true;
    const result = await getAffiliates({
      page: this.affiliatesPagination.page,
      pageSize: this.affiliatesPagination.pageSize,
      name: name,
    });

    runInAction(() => {
      if (result?.isSuccess) {
        const affiliates = this.affiliates;
        if (Array.isArray(affiliates)) {
          this.affiliates = [...affiliates, ...result.result];
        }
        this.affiliatesPagination.updateTotalCount(result.pagination.totalCount);
      } else {
        this.affiliates = [];
      }
      this.affiliatesLoading = false;
    });
  };

  loadTimeZone = async () => {
    if (!this.timeZone) {
      const result: Result = await getTimeZone();
      runInAction(() => {
        if (result.isSuccess) {
          this.timeZone = [...result.result];
        } else {
          this.timeZone = [];
        }
      });
    }
  };

  loadOpponents = async () => {
    if (!this.opponents) {
      const result: Result = await getOpponents();
      runInAction(() => {
        if (result.isSuccess) {
          this.opponents = [...result.result];
        } else {
          this.opponents = [];
        }
      });
    }
  };

  loadDefaultOpponentsAffiliates = async (id: number) => {
    if (!this.opponentsAffiliates) {
      const result = await getOpponentsAffiliates({
        id,
        page: this.affiliatesPagination.page,
        pageSize: this.affiliatesPagination.pageSize,
        name: "",
      });

      runInAction(() => {
        if (result && result.isSuccess) {
          this.opponentsAffiliates = result.result;
          this.affiliatesPagination.updateTotalCount(result.pagination.totalCount);
        } else {
          this.opponentsAffiliates = [];
        }
        this.affiliatesLoading = false;
      });
    }
  };

  searchOpponentsAffiliates = async (id: number, name: string) => {
    this.affiliatesLoading = true;

    const result = await getOpponentsAffiliates({
      id,
      page: 1,
      pageSize: 100,
      name: name,
    });

    runInAction(() => {
      if (result?.isSuccess) {
        this.opponentsAffiliates = [...result.result];
        this.affiliatesPagination.updateTotalCount(result.pagination.totalCount);
      } else {
        this.opponentsAffiliates = [];
      }
      this.affiliatesPagination.updatePage(1);
      this.affiliatesLoading = false;
    });
  };

  loadOpponentsAffiliates = async (id: number, name = "") => {
    this.affiliatesLoading = true;
    const result = await getOpponentsAffiliates({
      id,
      page: this.affiliatesPagination.page,
      pageSize: this.affiliatesPagination.pageSize,
      name: name,
    });

    runInAction(() => {
      if (result?.isSuccess) {
        const opponentsAffiliates = this.opponentsAffiliates;
        if (Array.isArray(opponentsAffiliates)) {
          this.opponentsAffiliates = [...opponentsAffiliates, ...result.result];
        }
        this.affiliatesPagination.updateTotalCount(result.pagination.totalCount);
      } else {
        this.opponentsAffiliates = [];
      }
      this.affiliatesLoading = false;
    });
  };

  loadBrandAdvertisers = async () => {
    const result = await getOfferBrandAdvertisers();
    runInAction(() => {
      if (result.isSuccess) {
        this.brandAdvertisers = [...result.result];
      } else {
        this.brandAdvertisers = [];
      }
    });
  };
}
