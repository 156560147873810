import { get, post } from "../request";

import { DefaultOption } from "shared/ui/Select";
import { mapToDefaultOptions } from "shared/utils";

export const StatisticsDictionaryApi = () => {
  const sendRequest = async (url: string, callback?: (value: Record<string, string>) => DefaultOption[]) => {
    const result = await get(url);

    if (result.isSuccess && result.result) {
      return { ...result, result: mapToDefaultOptions(result.result, callback) };
    }
    return result;
  };

  const getOffers = async () => {
    return await sendRequest("v1/statistic/get-offers", (data) => {
      return Object.entries(data).map(([key, value]) => ({
        value: key,
        label: `(${key}) ${value}`,
      }));
    });
  };

  const getTimeZone = async () => {
    return await sendRequest("v1/statistic/timezone");
  };

  const getAffiliates = async (params: Record<string, any>) => {
    return await post("v1/statistic/get-affiliates", params).then((res) => {
      if (res.isSuccess) {
        return { ...res, pagination: res.result.pagination, result: mapToDefaultOptions(res.result.ids) };
      }
    });
  };

  const getOpponents = async () => {
    return await sendRequest("v1/statistic/filter/opponents");
  };

  const getOpponentsAffiliates = async (params: Record<string, any>) => {
    return await post(`v1/statistic/filter/opponents-affiliates`, params).then((res) => {
      if (res.isSuccess) {
        return { ...res, pagination: res.result.pagination, result: mapToDefaultOptions(res.result.ids) };
      }
    });
  };

  // const getOpponentsAffiliates = async (id: number) => {
  //   return await sendRequest(`v1/statistic/filter/opponents-affiliates?id=${id}`);
  // };

  const getOfferBrandAdvertisers = async () => {
    return await sendRequest("v1/offer/brand-advertisers", (data) => {
      return Object.entries(data).map(([key, value]) => ({
        value: key,
        label: `(${key}) ${value}`,
      }));
    });
  };

  return { getOffers, getTimeZone, getAffiliates, getOpponents, getOpponentsAffiliates, getOfferBrandAdvertisers };
};
